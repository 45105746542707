<template>
  <div class="table__user" @mouseenter="openPopup" @mouseleave="closePopup">
    <div class="table__user-left">
      <div class="user__img">
        <img v-if="userAvatar.photos[1]" :src="'/' + 'api_v1' + userAvatar.photos[1].url" alt="">
        <img v-else src="/img/Avatar.c6df6ded.svg" alt="">
      </div>
      <div class="user__info">
        <div class="user__info-name">{{ row.name }}</div>
        <div class="user__info-role">{{ row.role.value }}</div>
      </div>
    </div>
    <div class="table__user-right">
      <div class="icon" v-if="!showPopup">
        <UiIcon value="grey_more" width="4px" height="10px" />
      </div>
      <div class="commands">
        <UiPopup v-if="showPopup" v-model="popup">
          <template #activator>
            <UiIcon
              color="#3A7CCA"
              value="blue_more"
              width="4px"
              height="10px"
            />
          </template>
          <template #body>
            <div class="button__body">
              <div class="button__body-change item" @mouseenter="change = true">
                <span>Изменить роль на</span>
                <UiIcon
                  value="smallArrowUp"
                  class="transform"
                  height="6px"
                  width="10px"
                />
              </div>
              <div
                @mouseenter="closeRoles"
                class="button__body-delete item"
                @click="$emit('deleteUser', row)"
              >
                <span v-if="roleName === 'Группы доступа'"
                  >Удалить из группы</span
                >
              </div>
              <div class="change__roles" @mouseleave="closeRoles">
                <ChangeRoles
                  :row="row"
                  v-if="change"
                  :items="allRoles"
                  @change="changeItemRole"
                />
              </div>
            </div>
          </template>
        </UiPopup>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, computed, onMounted,onBeforeMount } from 'vue'
import UiIcon from "./icon.vue"
import UiPopup from './popup.vue'
import ChangeRoles from "@/components/companyControlsCenter/components/AccessSettings/changeRoles.vue";
import { useStore } from '@/store/store.js'

const props = defineProps({
  row: {
    type: Object,
    default: () => {}
  },
  rowObject: {
    type: Object,
    default: () => {}
  },
  isShowPopup: {
    type: Boolean,
    default: false
  },
  isPopup: {
    type: Boolean,
    default: false
  },
  roleName: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['deleteUser','changeRole'])
const Store = useStore()

const allRoles = ref([])

const showPopup = ref(props.isShowPopup)
const popup = ref(props.isPopup)
const change = ref(false)

const positionRoleItems = computed(() => {
  if(props.roleName === 'Группы доступа') {
    return '176px'
  }
})
const openPopup = () => {
  showPopup.value = true
}
const closePopup = () => {
  showPopup.value = false
  popup.value = false
}
const closeRoles = () => {
  change.value = false
}
const changeItemRole = (item,row)=>{
  emit('changeRole',item,row)
}

const allUsers = computed(()=>{
  return Store.getters["users/getAllUsers"]
})
const userAvatar = computed( ()=>{
  return allUsers.value.filter(user=>user.id===props.row.id)[0]
})

onBeforeMount(async ()=>{
  allRoles.value = Store.getters['accessSettings/getAllRoles']
})

</script>

<style scoped lang="scss">
.table__user {
  display: flex;
  justify-content: space-between;
  padding: 2px 4px 1px 8px;
  height: 100%;
  width: 240px;

  &:hover {
    background: #f6f7fa;
  }

  &-left {
    display: flex;
    column-gap: 4px;
    align-items: center;

    .user__img {
      display: flex;
      align-items: center;
      justify-content:center;
      overflow: hidden;
      border: 1px solid #e2e8f0;
      width: 20px;
      height: 20px;
      border-radius: 100%;
    }

    .user__info {
      text-align: left;

      &-name {
        max-width: 184px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: #292929;
        white-space: nowrap;
      }

      &-role {
        font-size: 9px;
        font-style: normal;
        font-weight: 600;
        line-height: 80%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #a6aeb8;
      }
    }
  }

  &-right {
    cursor: pointer;
    position: relative;

    .icon {
      border-radius: 100%;
      width: 20px;
      height: 20px;
    }

    .commands {
      position: absolute;
      top: 3px;
      right: 0;

      ::v-deep(.popup) {
        .popup__activator {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
        }

        .popup__body {
          border: 1px solid #dce1e7;
          left: 1px;

          .button__body {
            padding: 9px 0;
            display: flex;
            flex-direction: column;
            row-gap: 7px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
            color: #292929;
            position: relative;
            height: max-content;

            &-change {
              display: flex;
              align-items: center;
              justify-content: space-between;
              column-gap: 16px;

              .transform {
                transform: rotate(270deg);
                color: #ffffff;
              }
            }

            .item {
              white-space: nowrap;
              text-align: left;
              padding: 0 12px;
            }

            &-delete {
              color: #c93131;
            }
          }

          .change__roles {
            top: 1px;
            position: absolute;
            left: v-bind(positionRoleItems);
          }
        }
      }
    }
  }
}
</style>
